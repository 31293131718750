import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Smile Makeover For A Better Life | The Smile Stylist" 
    description="Can a smile makeover really change your life? The simple and answer is yes. Find out how porcelain veneers can give you the confidence and happiness you deserve." 
    keywords="Smile Makeover" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Smile Makeover for a better life?</h1>
              <h2>Will your new smile change your life for the better?</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>What do you think the first thing people notice about you? Is it your height, your weight? Well according to some of the latest research it is, in fact, your smile. Your smile is worth a million bucks, and no matter if you are looking for love, or simply want to gain some confidence in the workplace or at home, one can say it all starts with your smile. So, with that in mind, does a smile makeover make for a better life?</p>
            <p>Well, the simple answer to this is yes. Smile makeovers allow you to gain confidence and allows you to be able to converse, laugh, and simply love life again without being concerned that your smile is ruining your mojo. No more avoiding situations with your friends where a lot of pictures will be taken or being cognizant that you will not be able to laugh. Reclaiming your life starts with a smile makeover, but it is not just a physical change you will experience.</p>
            <img className="bl__img" src={ require("../../images/desktop/Smile_Makeover_Will_Change_your.png" )} alt="Blog Banner" />
            <p>A smile transformation is not just about the physical changes, but rather the emotional changes as well. If you have been embarrassed about your smile you are often one to hide your face, and only get yourself in situations where you can control the conversation and general tone. A smile makeover can break you out of your cocoon and allow you to enjoy life again. This kind of attitude shift will allow you to get back out there and try new things, and you might be surprised to hear that smile makeovers are actually one of our favourite services to deliver.</p>
            <p>Yes, you read that right, our team loves delivering smile makeovers. It is not just because we enjoy the work, it is because we can see the changes in our clients almost immediately. Once the procedure is complete, the client will always want to see how it turned out, and the excitement and joy on their face are worth every single cent of our salaries. A smile is a universal feeling, and one thing that every single human on the Earth can relate to, and the fact that we are able to help someone smile just a little bit more makes our hearts melt. </p>
            <p>However, a smile makeover can only do so much. Any transformation that is completed needs to be matched with an oral health plan that will ensure the changes will be long-lasting. This includes things such as regular brushing, flossing, and regularly visiting the dentist for cleanings and check-ups. We want to make sure your smile lasts a lifetime, but you need to do your part too!</p>
            <img className="bl__img" src={ require("../../images/desktop/Smiles_Datting_veneers_Smile_Makeover.png" )} alt="Flash Your Smile" />
            <p>Think a smile makeover can change your life? Well now is a great time to call our team and see how we can help you get your mojo back. No matter if you are a single man looking to get a little more confident in the dating world, or a woman trying to get a little more confidence in the boardroom, a smile makeover from our team might be your ticket. Contact us today and see how your new smile might be able to change your life for the better.</p>
          </div>
          <div className="col-sm-4 blog-right">
            <img src={ require("../../images/desktop/get-virtual-smile.png" )} className="img-responsive wow animated zoomIn" alt="Wear A Smile" data-wow-delay="0.3s" />
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage